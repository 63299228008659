<template>
  <div class="container">
    <img :src="projectState.src" width="100%" :alt="projectState.text" />
  </div>
</template>

<script>
export default {
  name: 'ActivityStatus',
  data() {
    return {
      projectStateMap: {
        0: {
          text: '活动未开始',
          src: require('./assets/activity-not-start.png')
        },
        2: {
          text: '活动已结束',
          src: require('./assets/activity-end.png')
        }
      }
    }
  },
  computed: {
    projectState() {
      return {
        ...this.projectStateMap[this.$route.query.status]
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
